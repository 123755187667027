import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Log Pipe`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript",
        "metastring": "path=src/app/pipes/log-pipe.pipe.ts",
        "path": "src/app/pipes/log-pipe.pipe.ts"
      }}>{`import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'log',
})
export class LogPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(items: any[], callback: (item: any) => boolean): any {
        console.log(items);
        return null;
    }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript",
        "metastring": "path=src/app/pipes/log-pipe.pipe.spec.ts",
        "path": "src/app/pipes/log-pipe.pipe.spec.ts"
      }}>{`import { LogPipe } from './log-pipe.pipe';

describe('LogPipe', () => {
  it('creates an instance', () => {
    const pipe = new LogPipe();
    expect(pipe).toBeNull();
  });
});
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript",
        "metastring": "path=src/app/services/shared/shared.module.ts",
        "path": "src/app/services/shared/shared.module.ts"
      }}>{`import { NgModule } from '@angular/core';
import { LogPipe } from '../../pipes/log-pipe.pipe';

@NgModule({
    declarations: [
        LogPipe,
    ],
    exports: [
        LogPipe,
    ],
})
export class SharedModule { }
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      